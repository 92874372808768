import React from "react";
import Api from "../../../../config/api";

export default class Metadata extends React.Component {
    constructor(props) {
        super(props);

        // Grab the query params from the url
        //const params = new URLSearchParams(props.location.search);
        this.state = {
            chainId: props.params.chain_id,
            tokenAddress: props.params.token_address,
            tokenId: props.params.token_id,
            error: null,
        };
    }

    componentDidMount() {
        // document.body.innerHTML = `<pre style="word-wrap: break-word; white-space: pre-wrap;">${this.state.json}</pre>`;
        // Get the media record first
        Api.market({
            endpoint: "/chain/nft/metadata",
            method: "GET",
            headers: {
                "chain-id": this.state.chainId,
            },
            data: {
                address: this.state.tokenAddress,
                id: this.state.tokenId,
            },
        })
            .then((res) => {
                document.body.innerHTML = `<pre style="word-wrap: break-word; white-space: pre-wrap;">${JSON.stringify(res)}</pre>`;
            })
            .catch((e) => {
                this.setState({
                    error: `Failed to get metadata for token ${this.state.tokenAddress}:${this.state.tokenId} on chain ${this.state.chainId}`,
                });
            });
    }

    render() {
        return <p>{this.state.error}</p>;
    }
}
